const zimbabweCoordinates = [
  { lat: -17.8292, lng: 31.0522 },
  { lat: -20.0744, lng: 30.8328 },
  { lat: -20.17, lng: 28.58 },
  { lat: -17.9939, lng: 31.0481 },
  { lat: -18.9667, lng: 32.6333 },
  { lat: -19.4614, lng: 29.8022 },
  { lat: -19.0, lng: 28.9 },
  { lat: -18.9167, lng: 29.8167 },
  { lat: -17.8833, lng: 30.7 },
  { lat: -18.34, lng: 29.9 },
  { lat: -18.14, lng: 30.15 },
  { lat: -17.3497, lng: 30.1944 },
  { lat: -18.1897, lng: 31.5467 },
  { lat: -18.9833, lng: 32.65 },
  { lat: -17.3, lng: 31.3333 },
  { lat: -18.3647, lng: 26.5 },
  { lat: -22.2167, lng: 30.0 },
  { lat: -21.0389, lng: 31.6778 },
  { lat: -18.5367, lng: 32.1247 },
  { lat: -20.3333, lng: 30.0333 },
  { lat: -20.2, lng: 32.62 },
  { lat: -16.81, lng: 29.7 },
  { lat: -20.9389, lng: 29.0186 },
];

const southArficaCoordinates = [
  { lat: -26.2044, lng: 28.0456 },
  { lat: -33.9253, lng: 18.4239 },
  { lat: -26.2678, lng: 27.8585 },
  { lat: -33.9581, lng: 25.6 },
  { lat: -29.6167, lng: 30.3833 },
  { lat: -29.8833, lng: 31.05 },
  { lat: -25.7461, lng: 28.1881 },
  { lat: -25.6667, lng: 27.2428 },
  { lat: -27.7464, lng: 29.9328 },
  { lat: -33.0175, lng: 27.9047 },
  { lat: -26.3333, lng: 28.15 },
  { lat: -34.0403, lng: 18.6778 },
  { lat: -26.0936, lng: 28.0064 },
  { lat: -26.1625, lng: 27.8725 },
  { lat: -34.0506, lng: 18.6181 },
  { lat: -26.2125, lng: 28.2625 },
  { lat: -26.2178, lng: 28.1672 },
  { lat: -25.8603, lng: 28.1894 },
  { lat: -28.7383, lng: 24.7639 },
  { lat: -26.107, lng: 28.0517 },
  { lat: -26.8667, lng: 26.6667 },
  { lat: -33.8833, lng: 25.5 },
  { lat: -26.1, lng: 28.2333 },
  { lat: -28.232, lng: 30.566 },
  { lat: -27.65, lng: 27.2333 },
  { lat: -26.1883, lng: 28.3206 },
  { lat: -33.9667, lng: 22.45 },
  { lat: -26.715, lng: 27.1033 },
  { lat: -29.8167, lng: 30.85 },
  { lat: -26.1, lng: 27.7667 },
  { lat: -31.58, lng: 28.79 },
  { lat: -23.9, lng: 29.45 },
  { lat: -26.2547, lng: 28.4428 },
  { lat: -26.2672, lng: 28.1219 },
  { lat: -28.45, lng: 21.25 },
  { lat: -25.42, lng: 27.949 },
  { lat: -33.9, lng: 18.6 },
  { lat: -33.7242, lng: 18.9558 },
  { lat: -28.75, lng: 31.9 },
  { lat: -25.877, lng: 29.201 },
  { lat: -33.7667, lng: 25.4 },
  { lat: -29.3333, lng: 31.2917 },
  { lat: -33.645, lng: 19.4436 },
  { lat: -33.2996, lng: 26.52 },
  { lat: -33.5833, lng: 22.2 },
  { lat: -26.5333, lng: 29.9833 },
  { lat: -33.9367, lng: 18.8614 },
  { lat: -26.8142, lng: 27.8286 },
  { lat: -29.2, lng: 26.8333 },
  { lat: -22.95, lng: 30.4833 },
];

const ethiopiaCoordinates = [
  { lat: 9.03, lng: 38.74 },
  { lat: 9.5667, lng: 41.3833 },
  { lat: 7.2, lng: 38.6 },
  { lat: 6.7333, lng: 44.2667 },
  { lat: 8.5414, lng: 39.2689 },
  { lat: 13.4969, lng: 39.4769 },
  { lat: 5.9527, lng: 43.5516 },
  { lat: 7.05, lng: 38.4667 },
  { lat: 9.6, lng: 41.8667 },
  { lat: 11.6, lng: 37.3833 },
  { lat: 6.855, lng: 37.7808 },
  { lat: 6.0333, lng: 37.55 },
  { lat: 11.1333, lng: 39.6333 },
  { lat: 7.55, lng: 37.85 },
  { lat: 9.0833, lng: 43.0833 },
  { lat: 9.35, lng: 42.8 },
  { lat: 6.4083, lng: 38.3083 },
  { lat: 9.0833, lng: 36.55 },
  { lat: 9.6833, lng: 39.5333 },
  { lat: 10.3333, lng: 37.7167 },
  { lat: 5.0833, lng: 45.0833 },
  { lat: 8.2667, lng: 44.15 },
  { lat: 9.3111, lng: 42.1278 },
];

const mozambiqueCoordinates = [
  { lat: -25.9667, lng: 32.5833 },
  { lat: -25.9667, lng: 32.4667 },
  { lat: -15.1167, lng: 39.2667 },
  { lat: -19.8333, lng: 34.85 },
  { lat: -16.2333, lng: 39.9167 },
  { lat: -19.1167, lng: 33.45 },
  { lat: -14.5428, lng: 40.6728 },
  { lat: -14.9486, lng: 37.4144 },
  { lat: -17.8764, lng: 36.8872 },
  { lat: -16.1667, lng: 33.6 },
  { lat: -15.4667, lng: 36.9833 },
  { lat: -13.3167, lng: 35.2333 },
  { lat: -12.9667, lng: 40.55 },
  { lat: -25.05, lng: 33.65 },
  { lat: -23.8667, lng: 35.35 },
  { lat: -14.8167, lng: 36.55 },
  { lat: -13.1167, lng: 39.0 },
  { lat: -19.6167, lng: 34.75 },
  { lat: -16.85, lng: 36.9833 },
  { lat: -16.8496, lng: 38.26 },
  { lat: -23.865, lng: 35.3833 },
  { lat: -24.5253, lng: 33.0086 },
  { lat: -25.4, lng: 32.8 },
];

// export const getLatLongForCountry = (country) => {
//   const coordinates = {
//     zimbabwe: zimbabweCoordinates,
//     "our forest": zimbabweCoordinates,
//     "south africa": southArficaCoordinates,
//     ethiopia: ethiopiaCoordinates,
//     mozambique: mozambiqueCoordinates,
//   }[country?.toLowerCase()?.trim()] || [{ lat: 0, lng: 0 }];

//   // Fisher-Yates shuffle algorithm
//   for (let i = coordinates.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [coordinates[i], coordinates[j]] = [coordinates[j], coordinates[i]];
//   }

//   return coordinates[0] || [{ lat: 0, lng: 0 }];
// };

const countryCoordinates = {
  zimbabwe: zimbabweCoordinates,
  "our forest": zimbabweCoordinates,
  "south africa": southArficaCoordinates,
  ethiopia: ethiopiaCoordinates,
  mozambique: mozambiqueCoordinates,
};

const rotatedCoordinates = {};

function rotateCoordinates(coords) {
  const firstElement = coords?.shift();
  coords?.push(firstElement);
}

for (const country in countryCoordinates) {
  rotatedCoordinates[country] = [...countryCoordinates[country]];
  rotateCoordinates(rotatedCoordinates[country]);
}

export const getLatLongForCountry = (country) => {
  const coordinates = rotatedCoordinates[country?.toLowerCase()?.trim()] || [
    { lat: 0, lng: 0 },
  ];
  rotateCoordinates(rotatedCoordinates[country?.toLowerCase()?.trim()]);
  return coordinates[0] || [{ lat: 0, lng: 0 }];
};
